import { useRef, useCallback, useState, useEffect } from "react";
import { useAsync } from "react-async";
import { useLocation, useHistory } from "react-router-dom";
import { useUnmount, useList, useMap } from "react-use";
import { Box, Flex, Text, useToast } from "@chakra-ui/react";

import { useAPI, useSWRApi } from "api";
import { useLocale } from "app/locale";
import { hasPermission } from "app/permissions";
import { UploadCasesImagesContainer } from "app/upload-cases-images-container";
import {
  AddIcon,
  Alert,
  Button,
  CloseIcon,
  FileChooser,
  PDFViewer,
  Spinner,
  useModal
} from "components/core";
import { FetchCaseState } from "pages/cases/case-item/case-item-fetch-data";
import { isAttachments, SUPPORTED_ATTACHMENT_TYPES } from "utils/files";
import {
  CaseAttachmentList,
  UploadingAttachment
} from "./case-attachment-list";

export const CaseAttachments = ({
  pathologicCase,
  reload
}: Pick<FetchCaseState, "pathologicCase" | "reload">) => {
  const locale = useLocale();
  const toast = useToast();
  const fileChooserRef = useRef<FileChooser | null>(null);
  const modal = useModal();
  const api = useAPI();

  const { id: caseId, caseNumber } = pathologicCase;
  const { addFiles } = UploadCasesImagesContainer.useContainer();
  const { data, error, mutate } = useSWRApi(api.cases.listAttachments, caseId, {
    initialData: { caseId, attachments: pathologicCase.attachments }
  });
  const [viewFile, setViewFile] = useState<Medmain.Attachment>();
  // TODO Should we cancel upload when unmounting
  const [files, filesActions] = useList<UploadingAttachment>();
  const canAddAttachment = hasPermission("case/update", pathologicCase);

  // show automatically the single PDF available when data is loaded OR after the 1st PDF is added
  useEffect(() => {
    if (data?.attachments?.length === 1) {
      const firstAttachment = data?.attachments?.[0];
      setViewFile(firstAttachment);
    }
  }, [data]);

  if (!data) return <Spinner />;
  if (error) return <Alert>Error! {error.message}</Alert>;

  async function handleDelete(attachment: Medmain.Attachment) {
    const ok = await modal.confirm(
      locale.todo("Are you sure you want to delete the file?"),
      { title: locale.todo("Delete Attachment") }
    );
    if (!ok) return;
    try {
      await api.cases.deleteAttachment(caseId, attachment.filename);
      if (viewFile?.filename === attachment.filename) {
        setViewFile(undefined);
      }
      mutate();
      reload(); // Needed to sync comment count in the panel header
    } catch (e) {
      toast({
        description: `Failed to delete "${attachment.filename}"`,
        status: "error"
      });
    }
  }

  async function handleDownload(attachment: Medmain.Attachment) {
    try {
      const blobUrl = await api.cases.getAttachment(
        caseId,
        attachment.filename
      );
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", `${attachment.filename}`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      toast({
        description: `Failed to download "${attachment.filename}"`,
        status: "error"
      });
    }
  }

  // TODO Together with api restructuring, make the entire process better.
  async function onChooseFiles(addedFiles: File[]) {
    if (!ensureAttachment(addedFiles)) return;
    if (!ensureUniqueNames(addedFiles)) return;

    const attachments: UploadingAttachment[] = addedFiles.map(
      file => new UploadingAttachment(file)
    );
    filesActions.push(...attachments);

    await addFiles({
      caseId,
      caseNumber,
      filesToUpload: addedFiles,
      forceAttachment: true,
      onProgress: (file: Medmain.UploadFile, progress: number) => {
        const attachment = findAttachmentByName(file.filename);
        if (!attachment) return;
        filesActions.updateAt(
          files.indexOf(attachment),
          attachment.setProgress(progress)
        );
      },
      onError: (file: Medmain.UploadFile, error: Error) => {
        const attachment = findAttachmentByName(file.filename);
        if (!attachment) return;
        filesActions.removeAt(files.indexOf(attachment));
      },
      onEnd: (file: Medmain.UploadFile) => {
        const attachment = findAttachmentByName(file.filename);
        if (!attachment) return;
        mutate(data => {
          filesActions.filter(({ filename }) => filename !== file.filename);
          return { ...data, attachments: [...data.attachments, attachment] };
        });
        reload(); // Needed to sync comment count in the panel header
      }
    });

    function ensureAttachment(files: File[]) {
      const invalidFilenames: string[] = files
        .map(file => file.name)
        .filter(filename => !isAttachments(filename));

      if (invalidFilenames.length) {
        const description = `Unsupported files: ${invalidFilenames.join(", ")}`;
        toast({
          title: "Please choose attachments files.",
          description,
          status: "error"
        });
        return false;
      }

      return true;
    }

    function ensureUniqueNames(files: File[]): boolean {
      const filenames = data?.attachments.map(file => file.filename) || [];
      const duplicateFilenames = files
        .map(file => file.name)
        .filter(filename => filenames.includes(filename));

      if (duplicateFilenames.length) {
        const description = duplicateFilenames.join(", ");
        toast({
          title: "Duplicate files",
          description,
          status: "error"
        });
        return false;
      }

      return true;
    }

    function findAttachmentByName(filename: string) {
      return attachments.find(attachment => attachment.filename === filename);
    }
  }

  const attachments = [...data.attachments, ...files].sort((a, b) =>
    a.filename < b.filename ? -1 : a.filename > b.filename ? 1 : 0
  );

  return (
    <>
      <Box pt={2}>
        <FileChooser
          onChoose={onChooseFiles}
          ref={fileChooserRef}
          acceptedTypes={SUPPORTED_ATTACHMENT_TYPES.map(
            file => file.acceptedTypes
          )}
          acceptedExtensions={SUPPORTED_ATTACHMENT_TYPES.map(
            file => file.acceptedExtensions
          )}
        />
        <CaseAttachmentList
          caseId={caseId}
          attachments={attachments}
          canAddAttachment={canAddAttachment}
          onDeleteAttachment={handleDelete}
          onDownloadAttachment={handleDownload}
        />
        {canAddAttachment && (
          <Flex mt={4} justifyContent="flex-end">
            <Button
              onClick={() => fileChooserRef?.current?.open()}
              primary
              leftIcon={<AddIcon />}
            >
              {locale.todo("Add Attachments")}
            </Button>
          </Flex>
        )}
      </Box>
    </>
  );
};

type AttachmentViewerProps = {
  caseId: string;
  filename: string;
  createdAt: string;
};

export function AttachmentViewer(props: AttachmentViewerProps) {
  const { caseId, filename, createdAt } = props;
  const api = useAPI();
  const { get, set } = useObjectURLCache();
  const fetcher = useCallback(
    async (_, { signal }: AbortController) => {
      // Attachment has no explicit id, make union one here,
      //  in case a new file having same filename uses the URL of the old file.
      const key = [filename, createdAt].join();
      if (!get(key)) {
        set(key, await api.cases.getAttachment(caseId, filename, { signal }));
      }
      return get(key);
    },
    [api, caseId, filename, createdAt, get, set]
  );
  const { data, error, isPending } = useAsync(fetcher);

  const history = useHistory();
  const location = useLocation();

  if (error) return <Alert>Please go back and view this PDF again.</Alert>;
  if (isPending || !data) return <Spinner />;
  return (
    <Box data-testid="pdf-viewer">
      <Flex
        fontSize="xl"
        position="relative"
        mb={3}
        flexDirection={{ sm: "column-reverse", md: "row" }}
      >
        <Box
          flexGrow={1}
          alignSelf={{ sm: "flex-start", md: "center" }}
          wordBreak="break-word"
          title={filename}
        >
          <Text noOfLines={2}>{filename}</Text>
        </Box>
        <Button
          onClick={() =>
            history.push({ ...location, pathname: `/cases/${caseId}` })
          }
          rightIcon={<CloseIcon />}
          minWidth="152px"
          alignSelf={{ sm: "flex-end", md: "center" }}
          ml="10px"
          mb={{ sm: "10px", md: 0 }}
        >
          Back to Images
        </Button>
      </Flex>
      <PDFViewer url={data} height="1200" />
    </Box>
  );
}

/**
 * Provide a local cache to hold Object URLs and clean Objects when unmounting.
 * We could, but better not, put this at higher level such as CaseAttachmentsTabContent or even global, for better effect.
 * Better not to hold big Blobs in memory for a long time.
 */
function useObjectURLCache() {
  const [cache, { get, set }] = useMap<{ [key: string]: string }>({});
  // Clean up by letting GC know
  useUnmount(() => {
    Object.values(cache).forEach(x => {
      URL.revokeObjectURL(x);
    });
  });
  return { get, set };
}
