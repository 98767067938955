// Mapping between the filename extensions and the content type sent to the API when uploading
// No need to setup the usual file extensions (jpeg)
const contentTypesByExtension = {
  ".svs": "image/tiff",
  ".ndpi": "image/tiff"
};

export function getFileContentType({ name, type }): string {
  const extension = getFileExtension(name);

  const fileType = contentTypesByExtension[extension];

  if (fileType) {
    return fileType;
  }
  if (type) {
    return type;
  }
  throw new Error(`No file type associated to this extension "${extension}"`);
}

export function getFileExtension(filename: string): string {
  const index = filename.lastIndexOf(".");
  if (index !== -1) {
    return filename.slice(index);
  }
  return "";
}

const PDF_TYPE = {
  acceptedTypes: "application/pdf",
  acceptedExtensions: ".pdf"
};

const SUPPORTED_ATTACHMENT_IMAGE_TYPES = [
  {
    acceptedTypes: "image/png",
    acceptedExtensions: ".png"
  },
  {
    acceptedTypes: "image/jpeg",
    acceptedExtensions: ".jpeg"
  },
  {
    acceptedTypes: "image/jpeg",
    acceptedExtensions: ".jpg"
  }
] as const;

export const SUPPORTED_ATTACHMENT_TYPES_EXCLUDE_IMAGE = [
  PDF_TYPE,
  {
    acceptedTypes: "application/msword",
    acceptedExtensions: ".doc"
  },
  {
    acceptedTypes:
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    acceptedExtensions: ".docx"
  },
  {
    acceptedTypes: "application/vnd.ms-powerpoint",
    acceptedExtensions: ".ppt"
  },
  {
    acceptedTypes:
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    acceptedExtensions: ".pptx"
  },
  {
    acceptedTypes: "application/vnd.ms-excel",
    acceptedExtensions: ".xls"
  },
  {
    acceptedTypes:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    acceptedExtensions: ".xlsx"
  },
  {
    acceptedTypes: "text/csv",
    acceptedExtensions: ".csv"
  },
  {
    acceptedTypes: "application/zip",
    acceptedExtensions: ".zip"
  },
  {
    acceptedTypes: "image/gif",
    acceptedExtensions: ".gif"
  },
  {
    acceptedTypes: "image/bmp",
    acceptedExtensions: ".bmp"
  }
] as const;

export const SUPPORTED_ATTACHMENT_TYPES = SUPPORTED_ATTACHMENT_TYPES_EXCLUDE_IMAGE.concat(
  SUPPORTED_ATTACHMENT_IMAGE_TYPES
);

export function isPDF(filename: string): boolean {
  return checkFileExtension(filename, PDF_TYPE.acceptedExtensions);
}

export function isAttachments(filename: string): boolean {
  return SUPPORTED_ATTACHMENT_TYPES.some(file =>
    checkFileExtension(filename, file.acceptedExtensions)
  );
}

export function isAttachmentsExcludeImage(filename: string): boolean {
  return SUPPORTED_ATTACHMENT_TYPES_EXCLUDE_IMAGE.some(file =>
    checkFileExtension(filename, file.acceptedExtensions)
  );
}

const checkFileExtension = (
  filename: string,
  expectedExtension: string
): boolean => {
  return (
    expectedExtension ===
    filename
      .toLowerCase()
      .split(/(?=\.)/)
      .pop()
  );
};
