import { useEffect, useState } from "react";
import createAuth0Client from "@auth0/auth0-spa-js";
// TODO types will be exported as normal in v1.7
import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client";
// TODO type error in app/pagination.tsx when types installed
import { createContainer } from "unstated-next";
import orderBy from "lodash/orderBy";
import { useLocalStorage, useMount } from "react-use";

import { useAPI } from "api";
import { getConfig } from "api/config";
import { history } from "utils/history";
import { useDebug } from "utils/hooks";
import { hasPermission } from "./permissions";
import { AccountNames } from "types";

const SUPER_USER_KEY = "https://pidport.com/admin";

type User = {
  email: string;
  email_verified: boolean;
  name: string;
  family_name: string;
  given_name: string;
  nickname: string;
  picture: string;
  sub: string;
  updated_at: string;
};

type Auth0ProviderOptions = {
  redirect_uri: string;
  audience: string;
} & Auth0ClientOptions;

function getDefaultOptions() {
  const {
    auth0: { domain, clientID: client_id, redirectUri: redirect_uri, audience }
  } = getConfig();
  return { domain, client_id, redirect_uri, audience };
}

function useAuth0(options: Auth0ProviderOptions = getDefaultOptions()) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User>();
  const [auth0Client, setAuth0] = useState<Auth0Client>();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const debug = useDebug("auth");

  // TODO Add remote logging, such as Sentry and LogRocket
  useMount(() => {
    const initAuth0 = async () => {
      // The client could be either unauth / encountering network issue, or successfully authed through getTokenSilently.
      // No error should happen at this stage; otherwise, a misconfiguration or changing of implementation happens.
      const auth0FromHook = await createAuth0Client(options);
      setAuth0(auth0FromHook);
      // Callback from Auth0, check to ensure it is authentic
      if (
        options.redirect_uri.endsWith(window.location.pathname) &&
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        try {
          const { appState } = await auth0FromHook.handleRedirectCallback();
          // TODO Eliminate invalid targetUrl
          history.replace(appState?.targetUrl || "/");
        } catch (e) {
          debug("Unauthentic callback detected");
          // Unauthenticated request continues to a new Auth0 signin.
          // Authenticated user continues to this callback URL which is invalid in routing.
          // For both situations, simply change the URL to /
          history.replace("/");
        }
      }
      const isAuthenticated = await auth0FromHook.isAuthenticated();
      setIsAuthenticated(isAuthenticated);
      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        debug("authenticated user: %o", user);
        setUser(user as User);
      }
      setLoading(false);
    };
    initAuth0();
  });

  const loginWithPopup = async (o: PopupLoginOptions) => {
    setPopupOpen(true);
    try {
      await auth0Client!.loginWithPopup(o);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client!.getUser();
    setUser(user as User);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    const result = await auth0Client!.handleRedirectCallback();
    const user = await auth0Client!.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user as User);
    return result;
  };

  const setUserNames = ({ firstName, lastName }: AccountNames) => {
    if (!user) return;
    setUser({ ...user, given_name: firstName, family_name: lastName });
  };

  return {
    isAuthenticated,
    user,
    setUserNames,
    loading,
    popupOpen,
    loginWithPopup,
    handleRedirectCallback,
    getIdTokenClaims: (o?: getIdTokenClaimsOptions) =>
      auth0Client!.getIdTokenClaims(o),
    loginWithRedirect: (o?: RedirectLoginOptions) =>
      auth0Client!.loginWithRedirect(o),
    getTokenSilently: (o?: GetTokenSilentlyOptions) =>
      auth0Client!.getTokenSilently(o),
    getTokenWithPopup: (o?: GetTokenWithPopupOptions) =>
      auth0Client!.getTokenWithPopup(o),
    logout: (o: LogoutOptions | undefined) => auth0Client!.logout(o)
  };
}

export const AuthContainer = createContainer(useAuth0);
export const useAuth = AuthContainer.useContainer;

/**
 * Trigger login process
 *
 * TODO Find a way to test the auth logics
 */
export function useLogin() {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth();
  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    (async () => {
      await loginWithRedirect({
        appState: {
          // appState will be serialized in cookie, but it does not harm to encodedURI.
          targetUrl: encodeURI(
            `${window.location.pathname}${window.location.search}${window.location.hash}`
          )
        }
      });
    })();
  }, [loading, isAuthenticated, loginWithRedirect]);
}

export function useAccessToken() {
  const [token, setToken] = useState("");
  const { getTokenSilently } = useAuth();
  useEffect(() => {
    (async () => setToken(await getTokenSilently()))();
  });
  return token;
}

// TODO Split to account-container.ts
function useAccountHook() {
  const { user, logout } = useAuth();
  const api = useAPI();
  const [currentOrgId_, setCurrentOrgId] = useLocalStorage(
    "pidport-current-org-id", // local storage key
    ""
  );
  // FIXME: useLocalStorage updates its return type to allow undefined.
  const currentOrgId = currentOrgId_ || "";
  const [allOrgs, setAllOrgs] = useState<Medmain.Organization[]>([]);
  const [allContracts, setAllContracts] = useState<Medmain.Contract[]>([]);
  const [menuList, setMenuList] = useState<Medmain.MenuList>();
  const [isReady, setIsReady] = useState(false);

  const fetchOrgs = async () => {
    const { data }: { data: Medmain.Organization[] } = await api.orgs.list();
    setAllOrgs(data);
    const orgId = resolveCurrentOrgId({
      userVisibleOrgs: data,
      currentOrgId,
      isSuperUser: isSuperUser(user)
    });
    setCurrentOrgId(orgId);
  };

  const fetchContracts = async () => {
    const { data }: { data: Medmain.Contract[] } = await api.contracts.list();
    setAllContracts(data);
  };

  const fetchMenuList = async () => {
    const data: Medmain.MenuList = await api.accounts.getMenuList();
    setMenuList(data);
  };

  // TODO Error handling policy
  useEffect(() => {
    // Hold on until user authenticated
    if (!user) return;
    (async () => {
      try {
        await fetchOrgs();
      } catch (error) {
        console.error(`Unable to fetch the orgs`);
      }
      try {
        await fetchMenuList();
      } catch (error) {
        console.error(`Unable to fetch the menu list`);
      }
      setIsReady(true);
    })();
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    (async () => {
      try {
        if (isProprietorRole) await fetchContracts();
      } catch (error) {
        console.error(`Unable to fetch the contracts`);
      }
    })();
    // eslint-disable-next-line
  }, [menuList]);

  const getCurrentOrg = () => allOrgs.find(x => x.id === currentOrgId);

  const isMemberRole = allOrgs.some(org => hasPermission("role/member", org));
  const isMemberRoleOfOrg = orgId =>
    orgId !== "*" &&
    allOrgs.some(org => org.id === orgId && hasPermission("role/member", org));
  const isAdministratorRole = allOrgs.some(org =>
    hasPermission("role/administrator", org)
  );
  const isAdministratorRoleOfOrg = orgId =>
    orgId !== "*" &&
    allOrgs.some(
      org => org.id === orgId && hasPermission("role/administrator", org)
    );
  const isProprietorRole =
    menuList && hasPermission("role/proprietor", menuList);

  const organizations = orderBy(allOrgs, [org => org.name?.toLowerCase()]);

  const account: Medmain.Account | null = user
    ? {
        id: user.sub,
        email: user.email,
        firstName: user.given_name,
        lastName: user.family_name,
        avatarUrl: user.picture
      }
    : null;

  // TODO Here assert user to be existing
  return {
    isReady,
    user,
    language: "en",
    account,
    signOut: () =>
      logout({ returnTo: window.location.origin + getConfig().paths.logout }),
    allOrgs,
    allContracts,
    organizations,
    menuList,
    // the orgs the user can see in the "Org Context Switcher" and the "Org Picker" components
    visibleOrgs: organizations,
    currentOrgId,
    setCurrentOrgId,
    getCurrentOrg,
    // Super user, indicating at `id_token` level
    isSuper: () => isSuperUser(user),
    fetchOrgs,
    fetchContracts,
    isMemberRole,
    isMemberRoleOfOrg,
    isAdministratorRole,
    isAdministratorRoleOfOrg,
    isProprietorRole
  };
}

const isSuperUser = user => !!user?.[SUPER_USER_KEY];

export const AccountContainer = createContainer(useAccountHook);
export const useAccount = AccountContainer.useContainer;

// Given the orgs actually assigned to user (or the full list of orgs for super users)
// and the current org found in user settings,
// return the "current org" to be displayed in the "Context Org Switcher" component for example.
function resolveCurrentOrgId({
  userVisibleOrgs,
  currentOrgId,
  isSuperUser
}: {
  userVisibleOrgs: Medmain.Organization[];
  currentOrgId: string;
  isSuperUser: boolean;
}) {
  const isValidOrg = userVisibleOrgs.map(({ id }) => id).includes(currentOrgId);

  if (isSuperUser) {
    return isValidOrg ? currentOrgId : "*"; // fallback to all orgs for super users
  }

  if (userVisibleOrgs.length === 0) {
    return "";
  }

  // Fallback to the last org assign to the user
  const lastOrg = userVisibleOrgs[userVisibleOrgs.length - 1];
  return isValidOrg ? currentOrgId : lastOrg.id;
}

export function useOrgLookup() {
  const { allOrgs } = useAccount();
  const allOrgsById: Record<
    Medmain.Organization["id"],
    Medmain.Organization
  > = allOrgs.reduce((acc, org) => ({ ...acc, [org.id]: org }), {});

  return {
    getOrgById: (id: Medmain.Organization["id"]): Medmain.Organization =>
      allOrgsById[id] // provide a lookup function that be used in lists and forms
  };
}

export function useContractLookup() {
  const { allContracts } = useAccount();
  const allContractsById: Record<
    Medmain.Contract["id"],
    Medmain.Contract
  > = allContracts.reduce(
    (acc, contract) => ({ ...acc, [contract.id]: contract }),
    {}
  );

  return {
    getContractById: (id: Medmain.Contract["id"]): Medmain.Contract =>
      allContractsById[id] // provide a lookup function that be used in lists and forms
  };
}
